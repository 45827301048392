






























































































import { objetivos_medicion_vista_tabla } from "@/shared/dtos/Objetivos/objetivos_medicion_vista_tabla";
import objetivos_medicion_vista_tablaModule from "@/store/modules/objetivos_medicion-module";
import { Component, Vue, Prop } from "vue-property-decorator";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsString } from "@/utils/utils-string";
import { UtilsDate } from "@/utils/utils-date";
import { objetivos_medicion } from "@/shared/dtos/Objetivos/objetivos_medicion";
@Component({
  components: {
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    SelectObjetivo: () =>
      import("@/views/pacientes/objetivos/select-objetivo.vue"),
  },
})
export default class PacienteObjetivos extends Vue {
  @Prop({ default: true }) showopciones!: boolean;
  public search: string = "";
  public id_paciente: number = 0;
  public cargando: boolean = true;
  public modificando: boolean = false;
  public dialog: boolean = false;
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    objetivos_medicion_vista_tablaModule
      .getobjetivos_mediciones_paciente(this.id_paciente)
      .then(() => {
        this.cargando = false;
      });
  }

  public get headers() {
    return [
      {
        text: "Nombre de la medición",
        align: "start",
        value: "medicion",
      },
      { text: "Objetivo", value: "obj_medicion" },
      { text: "Fecha de alta", value: "fecha_alta_obj" },
      { text: "Fecha consegido", value: "fecha_conseguido" },
      { text: "Conseguido", value: "conseguido" },
      { text: "", value: "acciones" },
    ];
  }

  public get title() {
    return "Objetivos";
  }

  public get objetivos() {
    return objetivos_medicion_vista_tablaModule.objetivos_medicion_vista;
  }
  public format(fecha: Date): string {
    return UtilsDate.formatDateToVueTifyDDMMAAAA(fecha, false);
  }
  public eliminar_obj(item: objetivos_medicion_vista_tabla) {
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: "Obejtivo " + (item.conseguido ? "conseguido" : "NO conseguido"),
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text: "¿Seguro que quiere eliminar " + item.medicion + "?",
      accept: () => {
        let obj: objetivos_medicion = new objetivos_medicion(item);
        objetivos_medicion_vista_tablaModule
          .eliminarobjetivos_medicion(obj)
          .finally(() => {
            objetivos_medicion_vista_tablaModule.getobjetivos_mediciones_paciente(
              this.id_paciente
            );
          });
      },
      cancel: () => {},
    });
  }

  public conseguido(item: objetivos_medicion_vista_tabla) {
    if (this.modificando) {
      return;
    }
    this.modificando = true;
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: item.conseguido ? "danger" : "primary",
      title: "Obejtivo " + (item.conseguido ? "conseguido" : "NO conseguido"),
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text:
        "¿Seguro que quiere marcar " +
        item.medicion +
        " como" +
        " obejtivo " +
        (item.conseguido ? "NO conseguido" : "conseguido") +
        "?.",
      accept: () => {
        let obj: objetivos_medicion = new objetivos_medicion(item);
        obj.conseguido = !obj.conseguido;
        objetivos_medicion_vista_tablaModule
          .modificarobjetivos_medicion(obj)
          .finally(() => {
            objetivos_medicion_vista_tablaModule.getobjetivos_mediciones_paciente(
              this.id_paciente
            );
            this.modificando = false;
          });
      },
      cancel: () => {
        this.modificando = false;
        //item.conseguido = !item.conseguido;
      },
    });
  }
}
