var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showopciones)?_c('PacienteFichaOpciones',{attrs:{"id_paciente":_vm.id_paciente ? _vm.id_paciente : -1}}):_vm._e(),_c('vs-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.objetivos,"search":_vm.search,"loading":_vm.cargando,"loading-text":"cargando objetivos"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.showopciones)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"primary","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_circle_outline")]),_vm._v("Añadir objetivo ")],1),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.conseguido",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"label":item.conseguido ? 'Sí' : 'No'},on:{"click":function($event){return _vm.conseguido(item)}},model:{value:(item.conseguido),callback:function ($$v) {_vm.$set(item, "conseguido", $$v)},expression:"item.conseguido"}})]}},{key:"item.fecha_alta_obj",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.fecha_alta_obj ? _vm.format(new Date(item.fecha_alta_obj)) : ""))]}},{key:"item.fecha_conseguido",fn:function(ref){
var item = ref.item;
return [(!item.conseguido)?[_vm._v(_vm._s("No conseguido"))]:_vm._e(),(item.conseguido)?[_vm._v(_vm._s(item.fecha_conseguido ? _vm.format(new Date(item.fecha_conseguido)) : ""))]:_vm._e()]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":item.conseguido ? 'red lighten-3' : 'green lighten-1'},on:{"click":function($event){return _vm.conseguido(item)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v(_vm._s(item.conseguido ? "close" : "check"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.conseguido ? "Marcar como NO CONSEGUIDO" : "Marcar como conseguido"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.eliminar_obj(item)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])})],1),_c('SelectObjetivo',{attrs:{"dialog":_vm.dialog,"id_paciente":_vm.id_paciente},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }